import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import { ConverMoeny } from "../utility/help";
import { UserService } from "../service/user.service";
import { SlotService } from "../service/slot.service";
import { Deposit } from "./deposit";
import { Withdraw } from "./withdraw";
import { Help } from "./help";
import { User } from "./user";
import { Notice } from "./notice";
import { Reg } from "./reg";
import { Login } from "./login";
import { Point } from "./point";
import { Comp } from "./comp";
import { Note } from "./note";
import { Companylist } from "./companylist";

import { isBrowser, isMobile } from "react-device-detect";

export enum popupView {
  none = "none",
  deposit = "deposit",
  comp = "comp",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
  game = "game",
}

export enum gameView {
  none = "none",
  game = "game",
  slot = "slot",
  casino = "casino",
}

export enum Mode {
  none = "none",
  game = "game",
  slot = "slot",
}

async function sleep(ms: any) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

interface Props {
  user: any;
  activeHelp: boolean;
  authenticated: boolean;
  tryLogin: (id: any, pw: any) => any;
  tryLoginOut: () => any;
  updateUser: () => any;
  //   session: any;
}

interface State {
  ID: string;
  PW: string;
  balance: number;
  postCount: number;
  noteCount: number;
  note: any;
  popupStatuses: string;
  expanded: string;
  notes: any;
  mode: string;
  slots: any;
  slotgames: any;
  games: any;
  casionos: any;
  evolgame: any;
  isOpen: boolean;
  gameCode: any;
  gameName: any;
}

export class Header extends Component<Props, State> {
  userService = new UserService();
  slotService = new SlotService();
  Immediate : any = null

  constructor(props: Props) {
    super(props);
    this.state = {
      balance: 0,
      ID: "",
      PW: "",
      postCount: 0,
      note: [],
      notes: [],
      popupStatuses: popupView.none,
      noteCount: 0,
      expanded: "",
      mode: gameView.casino,
      slots: [],
      slotgames: [],
      games: [],
      casionos: [],
      evolgame: null,
      isOpen: false,
      gameCode: "",
      gameName: "",
    };

    this.handleUpdateNote();

    // this.updateUser();
  }

  componentDidMount() {
    if(this.Immediate == null) {
      this.Immediate = setImmediate(async () => {
        try {
          for (;;) {
            this.handleUpdateNote();
  
            await sleep(20000);
          }
        } catch (ex) {}
      });
  
    }
 
    this.handleUpdateNote();
 
    this.slotService.getSlotSetting().then((s) => {
      if (s.status === "success") {
        this.setState({ slots: s.slot, mode: Mode.slot });
      }
    });

    this.slotService.getCasinoSetting().then((s) => {
      if (s.status === "success") {
        const item: any = [];

        // for (const casiono of Object.values(s.casiono) as any) {
        //   if (casiono.nameEn !== "Evolution") {
        //     item.push(casiono);
        //   }
        // }

        this.setState({
          casionos: s.casiono,
        });
      }
    });
  }

  handleSlotGames = (gameCode: string) => {
    this.slotService.get_slot_by_company(gameCode).then((s: any) => {
      if (s.status === "success") {
        this.setState({ slotgames: s.games });
      }
    });
  };



  handleUpdateNote = () => {
    this.userService.get_user_note().then((data: any) => {
      if (data.status === "success") {
        if (data.count > 0 && this.state.postCount === 0) {
          this.setState({
            postCount: data.count,
            note: data.note,
            popupStatuses: popupView.note,
          });
        } else if (this.state.popupStatuses !== popupView.note) {
          this.setState({
            postCount: 0,
            note: [],
          });
        }

        // if (this.state.postCount > 0) {
        //   this.setState({ popupStatuses: popupView.note });
        // }
      } else if (this.props.authenticated) {
        this.props.tryLoginOut();
      }
    });
  };

  handleClosePopup = () => {
    this.setState({ popupStatuses: popupView.none });
  };

  handleChangePopup = (viewName: string) => {
    this.setState({ popupStatuses: viewName });
  };

  handleReadNote = (id: string) => {
    if (this.props.authenticated == true) {
      this.userService.do_read_note(id).then((data: any) => {
        if (data.status === "success") {
          let note = this.state.note.find((read: any) => read._id === id);
          if (note != null) {
            note.isRead = "y";
            this.setState({
              note: this.state.note,
            });
          }
        }
      });
    }
  };

  do_del_all_note = () => {
    if (this.props.authenticated == true) {
      this.userService.do_del_all_note().then((data: any) => {
        window.location.reload();
      });
    }
  };

  do_read_all_note = () => {
    if (this.props.authenticated == true) {
      this.userService.do_read_all_note().then((data: any) => {
        window.location.reload();
      });
    }
  };

  handleDel = (id: string) => {
    if (this.props.authenticated == true) {
      this.userService.do_del_note(id).then((data: any) => {
        if (data.status === "success") {
          this.setState({
            notes: data.note,
          });
          this.handleUpdateNote();
        }
      });
    }
  };

  handleOpenSlot = (code: string, company: string) => {
    this.slotService.OpenSlot(code, company).then((data: any) => {
      this.setState({ isOpen: false });
      if (data.status === "success") {
        let timn = new Date().getTime();
        window.open(data.gameUrl, "Data", "height=800,width=1400");
      }
    });
  };

  changePopup = (value: any) => {
    this.setState({ popupStatuses: value });
  };

  render() {
    const user = this.props.user;
    const authenticated = this.props.authenticated;

    const RenderPopup = () => {
      if (this.state.popupStatuses === popupView.deposit) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }

        return (
          <Deposit
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
            user={this.props.user}
            updateUser={this.props.updateUser}
          ></Deposit>
        );
      }

      if (this.state.popupStatuses === popupView.withdraw) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Withdraw
            handleClose={this.handleClosePopup}
            user={this.props.user}
            handleChange={this.handleChangePopup}
            updateUser={this.props.updateUser}
          ></Withdraw>
        );
      }

      if (this.state.popupStatuses === popupView.comp) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }

        return (
          <Comp
            handleClose={this.handleClosePopup}
            user={this.props.user}
            handleChange={this.handleChangePopup}
            updateUser={this.props.updateUser}
          ></Comp>
        );
      }

      if (this.state.popupStatuses === popupView.notice) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Notice
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
          ></Notice>
        );
      }

      if (this.state.popupStatuses === popupView.note) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Note
            handleClosePopup={this.handleClosePopup}
            handleChange={this.handleChangePopup}
            // handleDel={this.handleDel}
            // handleReadNote={this.handleReadNote}
            handleClose={this.handleClosePopup}
            note={this.state.note}
          ></Note>
        );
      }

      if (this.state.popupStatuses === popupView.point) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Point
            handleClose={this.handleClosePopup}
            user={this.props.user}
            handleChange={this.handleChangePopup}
            updateUser={this.props.updateUser}
          ></Point>
        );
      }

      if (this.state.popupStatuses === popupView.reg) {
        return (
          <Reg
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
          ></Reg>
        );
      }

      if (this.state.popupStatuses === popupView.login) {
        return (
          <Login
            handleClose={this.handleClosePopup}
            tryLogin={this.props.tryLogin}
            changePopup={this.changePopup}
            handleChange={this.handleChangePopup}
          ></Login>
        );
      }

      if (this.state.popupStatuses === popupView.help) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Help
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
          ></Help>
        );
      }
      if (this.state.popupStatuses === popupView.user) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <User
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
            user={this.props.user}
          ></User>
        );
      }
      if (this.state.popupStatuses === popupView.game) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }

        return (
          <Companylist
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
            gameCode={this.state.gameCode}
            gameName={this.state.gameName}
          ></Companylist>
        );
      }

      return <div></div>;
    };

    const RendePopup = () => {
      // if (0 < this.state.postCount) {
      //   this.setState({ popupStatuses: popupView.note });
      // }

      // this.setState({ popupStatuses: popupView.note });

      return <div></div>;
    };

    const RenderNoteCount = () => {
      if (this.state.noteCount === 0) {
        return <></>;
      }

      return (
        <>
          <span className="count">{this.state.noteCount}</span>
        </>
      );
    };

    const RenderLogin = () => {
      if (authenticated === false) {
        return (
          <>
            <div className="bal-container">
              <div className="before-login active">
                <div className="desktop">
                  <button
                    className="login-btn"
                    data-toggle="modal"
                    onClick={() => {
                      this.setState({ popupStatuses: popupView.login });
                    }}
                  >
                    <i className="fa fa-edit"></i> 로그인
                  </button>
                  <button
                    className="join-btn"
                    data-toggle="modal"
                    onClick={() => {
                      this.setState({ popupStatuses: popupView.reg });
                    }}
                  >
                    <i className="far fa-gem"></i> 회원가입
                  </button>
                </div>
              </div>
            </div>
          </>
        );
      }


      console.log(this.props.user)

      return (
        <>
          <div className="bal-container">
            <div className="after-login active">
              <ul className="al-ul sidebar-right">
                <button className="sb-close-btn">
                  <i className="fa fa-window-close" aria-hidden="true"></i> 닫기
                </button>
                <li>
                  <div className="labels">
                    <i className="icon icon-User"></i>
                    <p>아이디</p>
                  </div>
                  <div className="info">
                    <p>
                      {this.props.user.id} <span>님</span>
                    </p>
                  </div>
                </li>
                <li>
                  <div className="labels">
                    <i className="icon icon-Dollar"></i>
                    <p>보유머니</p>
                  </div>
                  <div className="info">
                    <p>
                      {this.props.user && ConverMoeny(this.props.user.balance + this.props.user.in_balance)} <span>원</span>
                    </p>
                  </div>
                </li>
                <li>
                  <div className="labels">
                    <i className="icon icon-Bag"></i>
                    <p>금고머니</p>
                  </div>
                  <div className="info">
                    <p>
                      {ConverMoeny(this.props.user.point)} <span>원</span>
                    </p>
                  </div>
                </li>
                <li>
                  <div className="labels">
                    <i className="icon icon-Star"></i>
                    <p></p>
                  </div>
                  <div className="info">
                    <p></p>
                  </div>
                </li>
                <li>
                  <div className="labels">
                    <i className="icon icon-Tag"></i>
                    <p></p>
                  </div>
                  <div className="info">
                    <p></p>
                  </div>
                </li>
                <li className="btn-grp">
                  <div className="mess-notif">
                    <a
                      href="javascript:;"
                      className="mess-icon"
                      data-toggle="modal"
                      data-target=".customerModal"
                      onClick={() => {
                        this.setState({ popupStatuses: popupView.note }); //쪽지
                      }}
                    >
                      {/* <span className="count">{this.state.noteCount}</span> */}
                      {RenderNoteCount()}
                      <i className="fa fa-bell"></i>
                    </a>
                  </div>
                  <button
                    className="mypage-btn"
                    data-toggle="modal"
                    data-target=".mypageModal"
                    onClick={() => {
                      this.setState({ popupStatuses: popupView.point }); //금고
                    }}
                  >
                    <i className="icon icon-Settings"></i> 머니이동
                  </button>
                  <button
                    className="logout-btn"
                    onClick={() => {
                      this.props.tryLoginOut();
                    }}
                  >
                    <i className="icon icon-PowerOff"></i> 로그아웃
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </>
      );
    };

    const RenderLoginMobile = () => {
      if (authenticated === false) {
        return (
          <>
            <div className="header-bottom">
              <div className="bal-container">
                <div className="before-login h-100 active">
                  <div className="mobile">
                    <button
                      data-toggle="modal"
                      data-target=".loginModal"
                      onClick={() => {
                        this.setState({ popupStatuses: popupView.login });
                      }}
                    >
                      <i className="fa fa-edit"></i> 로그인
                    </button>
                    <button
                      data-toggle="modal"
                      data-target=".joinModal"
                      onClick={() => {
                        this.setState({ popupStatuses: popupView.reg });
                      }}
                    >
                      <i className="far fa-gem"></i> 회원가입
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      }

      return (
        <>
          <div className="header-bottom">
            <div className="bal-container">
              <div className="after-login active">
                <div className="mobile">
                  <button className="right-menu-btn">
                    <i className="fa fa-cogs"></i> 내정보보기
                  </button>
                  <button className="logout-btn" 
                     onClick={() => {
                      this.props.tryLoginOut();
                    }}>
                    <i className="fa fa-sign-out-alt"></i> 로그아웃
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    };

    return (
      <>
        <header className="header-main">
          <div className="header-top">
            <div className="container">
              <button className="left-menu-btn">
                <span></span>
                <span></span>
                <span></span>
              </button>
              <div className="logo-container">
                <a href="/" className="ls-logo">
                {
                  isMobile ? 
                    <img className="logo-img" src="img/logo/logo-img.png" style={{width : '50%',  }}/>
                  : <img className="logo-img" src="img/logo/logo-img.png" />
                }
                  {
                  isMobile ? 
                  <img className="grand" src="img/logo/grand.png"  style={{width : '60%', left : '15%', top: '-10px'}}/>
                  : 
                  <img className="grand" src="img/logo/grand.png" />
                }
                  
                </a>
              </div>

              <ul className="bs-ul main-menu sidebar-left">
                <li>
                  <a
                    href="javascript:;"
                    data-toggle="modal"
                    data-target=".depositModal"
                    onClick={() => {
                      this.setState({ popupStatuses: popupView.deposit }); //입금신청
                    }}
                  >
                    입금신청
                  </a>
                </li>
                <li>
                  <a
                    href="javascript:;"
                    data-toggle="modal"
                    data-target=".withdrawModal"
                    onClick={() => {
                      this.setState({ popupStatuses: popupView.withdraw }); //출금신청
                    }}
                  >
                    출금신청
                  </a>
                </li>
                <li>
                  <a
                    href="javascript:;"
                    data-toggle="modal"
                    data-target=""
                    onClick={() => {
                      this.setState({ popupStatuses: popupView.point }); //금고
                    }}
                  >
                    금고
                  </a>
                </li>
                <li>
                  <a
                    href="javascript:;"
                    data-toggle="modal"
                    data-target=""
                    onClick={() => {
                      this.setState({ popupStatuses: popupView.comp }); //콤프
                    }}
                  >
                    콤프
                  </a>
                </li>
                <li>
                  <a
                    href="javascript:;"
                    data-toggle="modal"
                    data-target=".mypageModal"
                    onClick={() => {
                      this.setState({ popupStatuses: popupView.user }); //마이페이지
                    }}
                  >
                    마이페이지
                  </a>
                </li>
                <li>
                  <a
                    href="javascript:;"
                    data-toggle="modal"
                    data-target=".customerModal"
                    onClick={() => {
                      this.setState({ popupStatuses: popupView.help }); //고객센터
                    }}
                  >
                    고객센터
                  </a>
                </li>
                <li>
                  <a
                    href="javascript:;"
                    data-toggle="modal"
                    data-target=".noticeModal"
                    onClick={() => {
                      this.setState({ popupStatuses: popupView.notice }); //공지사항
                    }}
                  >
                    공지사항
                  </a>
                </li>
              </ul>

              {RenderLogin()}
            </div>
          </div>
          {RenderLoginMobile()}
        </header>

        {RenderPopup()}
        {RendePopup()}
      </>
    );
  }
}
